const mainstreamBands = [
  "The Beatles",
  "The Rolling Stones",
  "Led Zeppelin",
  "Pink Floyd",
  "Queen",
  "Nirvana",
  "Metallica",
  "AC/DC",
  "The Who",
  "U2",
  "Red Hot Chili Peppers",
  "Foo Fighters",
  "Guns N' Roses",
  "Coldplay",
  "Linkin Park",
  "Green Day",
  "Pearl Jam",
  "R.E.M.",
  "Aerosmith",
  "The Clash",
  "The Eagles",
  "The Doors",
  "Fleetwood Mac",
  "The Police",
  "Journey",
  "Bon Jovi",
  "KISS",
  "Van Halen",
  "Radiohead",
  "The Cure",
  "The Smashing Pumpkins",
  "Alice in Chains",
  "Blink-182",
  "Oasis",
  "The Killers",
  "Nine Inch Nails",
  "Muse",
  "The Black Keys",
  "The White Stripes",
  "Arctic Monkeys",
  "Imagine Dragons",
  "Maroon 5",
  "Weezer",
  "Depeche Mode",
  "Iron Maiden",
  "Tool",
  "Panic! At The Disco",
  "Fall Out Boy",
  "My Chemical Romance",
  "Kings of Leon",
  "The Strokes",
];

export default mainstreamBands;

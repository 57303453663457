import React, { useState } from "react";

const SearchBar = ({ onSearch }) => {
  const [artistName, setArtistName] = useState("");

  const handleSearch = () => {
    onSearch(artistName);
  };

  return (
    <div>
      <input
        type="text"
        value={artistName}
        onChange={(e) => setArtistName(e.target.value)}
        placeholder="Enter artist name"
      />
      <button onClick={handleSearch}>Search</button>
    </div>
  );
};

export default SearchBar;

import React, { useState } from "react";
import "./BingoCard.css";

const BingoCard = ({ artists }) => {
  const [selectedSquares, setSelectedSquares] = useState(
    Array.from({ length: 5 }, () => Array(5).fill(false))
  );

  const grid = Array.from({ length: 5 }, () => Array(5).fill(null));

  // Populate the grid with artist names
  let artistIndex = 0;
  for (let row = 0; row < 5; row++) {
    for (let col = 0; col < 5; col++) {
      if (row === 2 && col === 2) {
        grid[row][col] = "Free"; // Free space in the center
      } else {
        if (artistIndex < artists.length) {
          grid[row][col] = artists[artistIndex];
          artistIndex++;
        }
      }
    }
  }

  const handleSquareClick = (row, col) => {
    const newSelectedSquares = [...selectedSquares];
    newSelectedSquares[row][col] = !newSelectedSquares[row][col];
    setSelectedSquares(newSelectedSquares);
  };

  return (
    <div className="bingo-card">
      <h2>Bingo Card</h2>
      <div className="grid">
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((cell, colIndex) => (
              <div
                key={colIndex}
                className={`cell ${cell === "Free" ? "free" : ""} ${
                  selectedSquares[rowIndex][colIndex] ? "selected" : ""
                }`}
                onClick={() => handleSquareClick(rowIndex, colIndex)}
              >
                <span>{cell}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BingoCard;

import React, { useState } from "react";
import SearchBar from "./components/SearchBar";
import BingoCard from "./components/BingoCard";
import mainstreamBands from "./data/mainstreamBands";
import "./App.css";

const App = () => {
  const [artistData, setArtistData] = useState(null);
  const [similarArtists, setSimilarArtists] = useState([]);

  const fetchArtistData = (artistName) => {
    fetch(
      `https://musicbrainz.org/ws/2/artist/?query=artist:${artistName}&fmt=json&inc=tags`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.artists && data.artists.length > 0) {
          const artist = data.artists[0];
          const genres = artist.tags ? artist.tags.map((tag) => tag.name) : [];
          setArtistData({ ...artist, genres });
          fetchSimilarArtists(artist.id);
        } else {
          setArtistData(null);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const LASTFM_API_KEY = "34248068803deb69cd520e8b33cff6c2"; // Replace with your Last.fm API key

  const fetchSimilarArtists = (musicBrainzId) => {
    fetch(
      `http://ws.audioscrobbler.com/2.0/?method=artist.getsimilar&mbid=${musicBrainzId}&api_key=${LASTFM_API_KEY}&format=json`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.similarartists && data.similarartists.artist) {
          const similarArtists = data.similarartists.artist.map((artist) => ({
            name: artist.name,
            url: artist.url,
            image: artist.image[2]["#text"], // Medium size image
            listeners: artist.listeners,
          }));
          setSimilarArtists(similarArtists);
        } else {
          setSimilarArtists([]);
        }
      })
      .catch((error) =>
        console.error("Error fetching similar artists:", error)
      );
  };

  const mixBands = () => {
    const allBands = [
      ...similarArtists.map((artist) => artist.name),
      ...mainstreamBands,
    ];
    const selectedBands = [];

    while (selectedBands.length < 25) {
      if (allBands.length > 0) {
        const randomIndex = Math.floor(Math.random() * allBands.length);
        const selectedBand = allBands.splice(randomIndex, 1)[0];
        selectedBands.push(selectedBand);
      } else {
        // Fill remaining spaces with placeholder text
        selectedBands.push("Band Not Found");
      }
    }

    return selectedBands;
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Concert T-Shirt Bingo</h1>
      </header>
      <main>
        <SearchBar onSearch={fetchArtistData} />
        {artistData && <BingoCard artists={mixBands()} />}
      </main>
    </div>
  );
};

export default App;
